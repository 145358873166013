<template>
  <!-- delete-service -->
  <div class="modal fade delete-category" id="sync-modal" tabindex="-1" aria-labelledby=" delete-serviceLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <div class="modal-header pb-0" style="text-align: right">
          <h2 style="width: 100%;text-align: right">تقييم أداء</h2>
        </div>
        <div class="modal-body pb-0">
          <div class="row">
            <div class="col-md-12 text-center mb-4">
              <h3 style="    margin: 0;">مطابقة كشف الاسماء مع المستفيدين</h3>
              <star-rating :star-size="40" :show-rating="false" style="    justify-content: space-around;"
                           v-model:rating="rating1"/>
            </div>
            <hr>
            <div class="col-md-12 text-center mb-4">
              <h3 style="    margin: 0;">أداء المندوب</h3>
              <star-rating :star-size="40" :show-rating="false" style="    justify-content: space-around;"
                           v-model:rating="rating2"/>
            </div>
            <hr>

            <div class="col-md-12 text-center mb-4">
              <h3 style="    margin: 0;">سلوك المستفيدين عند التسليم</h3>
              <star-rating :star-size="40" :show-rating="false" style="    justify-content: space-around;"
                           v-model:rating="rating3"/>
            </div>
            <hr>

            <div class="col-md-12 text-center">
              <h2>ملاحظات
                <span style="color:red;">*</span>
              </h2>
              <textarea v-model="notes" style="text-align: center;direction: rtl;    height: 114px !important;" name=""
                        id="" class="form-control notes-text-area" rows="5"
                        placeholder="ادخل ملاحظات التوزيع"></textarea>
            </div>
          </div>


        </div>
        <div class="modal-footer border-0" style="justify-content: space-evenly !important;">
          <button type="button" :class="['btn btn-success',loading ? 'disabled' : '']" :disabled="loading"
                  @click="uploadVisits($event)">Confirm Sync

          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import {addOrUpdateData, getAllData, getDataByKey} from "@/database";
import StarRating from 'vue-star-rating'

export default {
  name: 'delete-services',
  components: {StarRating},
  data() {
    return {
      item: {
        notes: '',
      },
      notes: '',
      rating1: null,
      rating2: null,
      rating3: null,
      user: null,
      index: null,
      visit_id: null,
      search_value: null,
      current_page: null,
      loading: false,

    }
  },
  async mounted() {
    eventBus.on('show_rating_modal', function (item) {
      this.notes = '';
      this.item = item.item
      this.visit_id = item.visit_id
      this.index = item.index
      getAllData('auth-user').then((data) => {
        this.user = data[0];

      })
    }.bind(this))
  },


  methods: {
    async uploadVisits(event) {
      try {
        let visit = await getDataByKey(this.visit_id, 'current-visits')
        if (!this.notes.length) {
          $('.notes-text-area').css('border', '1px solid red')
          return false
        } else {
          $('.notes-text-area').css('border', '1px solid #dee2e6')
        }
        this.loading = true;

        var response = await this.$axios.post('/api/visits/' + this.visit_id + '/sync', {
          data: visit,
          employee_id: this.user.employee_id,
          rating1: this.rating1,
          rating2: this.rating2,
          rating3: this.rating3,
          notes: this.notes,
        });
        $('#sync-modal').modal('toggle')

        if (response.data.status == true) {
          this.loading = false;

          visit.last_sync_at = response.data.last_sync_at
          this.item.last_sync_at = response.data.last_sync_at;
          const cleanData = JSON.parse(JSON.stringify(visit));
          await addOrUpdateData(cleanData, 'current-visits')
        }
        eventBus.emit('refresh-list')

        console.log(response, 'response')
      } catch (error) {
        this.loading = false;
        console.log(error)
      }

    },
    async confirm_complete(event, visit_id, item) {
      try {
        let visit = await getDataByKey(visit_id, 'current-visits')
        this.loading = true;
        var response = await this.$axios.post('/api/visits/' + visit_id + '/store-delivery-accept?user_id=' + this.user.id, {
          data: this.item.items,
          notes: this.notes,
          employee_id: this.user.employee_id
        });
        $('#done-delivery').modal('toggle')
        eventBus.emit('refresh-list')
        this.loading = false;
        console.log(response, 'response')
      } catch (error) {
        this.loading = false;
        console.log(error)
      }

    },
    showError(error) {
      let message_title = error.response.data.message + '\n';
      let message = message_title;
      let errors = error.response.data.errors;
      $.each(errors, function (key, val) {
        $.each(val, function (key2, val2) {
          message += val2 + '\n'
        });
      });
      this.notify("error", message, 'fas fa-times p-1', 'danger')
    },

  }
}
</script>
<style scoped>
.staff-members >>> .multiselect__tags {
  margin-left: -1px !important;
  border: none !important;
}
</style>
