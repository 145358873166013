import { createApp } from 'vue'
import bootstrap from 'bootstrap'
import App from './App.vue'
import axios from './axios';
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import './registerServiceWorker'
import 'bootstrap/dist/css/bootstrap.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faHome, faBell,faBrush, faHistory,faArrowLeft, faSignOutAlt,faCog,faCheck,faClock,faDownload,faRotateBackward,faMap, faLocation,faPhone,faUser,faUserGroup } from '@fortawesome/free-solid-svg-icons';
// import { subscribeUser } from './pushsubscription';

library.add(faHome,faBrush, faBell, faHistory,faArrowLeft,faSignOutAlt,faCog,faCheck,faClock,faDownload,faRotateBackward,faMap,faLocation,faPhone,faUser,faUserGroup);

const app = createApp(App);

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAHhsTaj-1dYdy3IfwPVSqIThpIJH8LISk',
    },
})


app.component('font-awesome-icon', FontAwesomeIcon);

app.config.globalProperties.$axios = axios;
// app.config.globalProperties.$subscribeUser = subscribeUser;

app.use(router).mount('#app')
