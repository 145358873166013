<template>
  <!-- Settings Header -->
  <div class="settings-header" style="padding: 20px 0 40px 0;font-size: 20px;">
    <h1 style="font-size: 20px;">Settings</h1>
  </div>

  <!-- Settings Container -->
  <div style="padding-right: 10px;padding-left: 10px">
    <div class="container settings-container">
      <!-- Get Distributions -->
      <div class="setting-item">
        <span>Clear</span>
        <button type="button" :class="['btn btn-info btn-sm',!is_online || loading ? 'disabled' : '']"
                @click="click_clear()" :disabled="!is_online || loading">
          <font-awesome-icon icon="brush"/>
          Clear
          <div v-if="clear_loader" class="spinner-border text-black"
               style="    --bs-spinner-width: 1rem;--bs-spinner-height: 1rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
      <div class="setting-item">
        <span>Get Distributions</span>
        <button type="button" :class="['btn btn-primary btn-sm',!is_online || loading ? 'disabled' : '']"
                @click="getCurrentVisitsOnlyAddData()" :disabled="!is_online || loading">
          <font-awesome-icon icon="download"/>
          Download New
          <div v-if="download_loader" class="spinner-border text-light"
               style="    --bs-spinner-width: 1rem;--bs-spinner-height: 1rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>

      <!-- Sync All Camps -->
      <div class="setting-item">
        <span>Activate Sync All Camps</span>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="syncAllCamps" v-model="$root.is_can_sync_activated">
        </div>
      </div>

      <div class="setting-item">
        <span>Update App</span>
        <button type="button" :class="['btn btn-warning btn-sm',!is_online || loading_reload ? 'disabled' : '']"
                @click="updateApp()" :disabled="!is_online || loading_reload">
          <font-awesome-icon icon="rotate-backward"/>
          Update
        </button>
      </div>

      <!-- Logout -->
      <div class="setting-action">
        <button type="button" class="btn logout-btn" @click="logout">Logout</button>
      </div>
    </div>

  </div>

  <div class="modal fade" id="confirm-clear" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Confirm Clear</h5>

        </div>
        <div class="modal-body">
          <p>There are some Distributions needs Sync, Do you need to continue? </p>
          <p>يوجد بعض التوزيعات لم يتم عمل لها مزامنة, هل انت متأكد من مسح البيانات؟</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel_modal">Cancel And Review</button>
          <button type="button" class="btn btn-primary" @click="getCurrentVisitsData">
            <font-awesome-icon icon="brush"/>
            <div v-if="clear_loader" class="spinner-border text-white"
                 style="    --bs-spinner-width: 1rem;--bs-spinner-height: 1rem;" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            Confirm Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {addOrUpdateData, getAllData, clearAllData, addData} from "@/database";

export default {
  components: {},
  data() {
    return {
      username: '',
      password: '',
      user: null,
      loading: false,
      showInstallButton: false,
      error: null,
      is_online: false,
      loading_reload: false,
      clear_loader: false,
      download_loader: false,

    };
  },
  mounted() {
    this.is_online = true;
    // this.is_online = navigator.onLine;
    getAllData('auth-user').then((data) => {
      this.user = data[0];

    })
  },
  methods: {
    logout() {
      localStorage.removeItem('token');

      // Redirect or update the UI to indicate the user is logged in
      this.$router.push('/'); // Adjust the path as needed
    },
    async cancel_modal() {
      $('#confirm-clear').modal('toggle')
    },
    async click_clear() {
      try {
        var is_clear = true;
        await getAllData('current-visits').then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (!data[i].last_sync_at && this.user.employee_id == data[i].employee_id) {
              is_clear = false;
              $('#confirm-clear').modal('toggle')
              return false;
            }
          }
        })
        if (is_clear) {
          await clearAllData('current-visits')
          this.clear_loader = true;
          this.loading = true;
          setTimeout(function () {
            this.loading = false;
            this.clear_loader = false;
          }.bind(this), 1000)
        }

      } catch (error) {
        console.log(error)
      }
    },
    async getCurrentVisitsData() {
      try {
        await clearAllData('current-visits')
        this.clear_loader = true;
        this.loading = true;
        setTimeout(function () {
          $('#confirm-clear').modal('toggle')
          this.loading = false;
          this.clear_loader = false;
        }.bind(this), 1000)
      } catch (error) {
        console.log(error)
      }
    },
    async getCurrentVisitsOnlyAddData() {
      try {
        // if (navigator.onLine) {
        this.loading = true;
        this.download_loader = true;
        var response = await this.$axios.get('/api/visits/current');
        this.data = response.data.data
        this.loading = false;
        this.download_loader = false;

        var app_version_flag = false;
        await getAllData('app-version').then(async (data) => {
          if (data[data.length - 1] && data[data.length - 1].app_version && data[data.length - 1].app_version != response.data.app_version) {
            app_version_flag = true;
            await addOrUpdateData([{'app_version': response.data.app_version}], 'app-version')
            location.reload();
          }
        });
        if (!app_version_flag){
          addOrUpdateData([{'app_version': response.data.app_version}], 'app-version')
        }

        getAllData('current-visits').then(async (data) => {
          var flag = false;
          for (let j = 0; j < this.data.length; j++) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].id == this.data[j].id) {
                flag = true;
                break;
              }
            }
            if (flag) {
              break;
            } else {
              await addData(this.data[j], 'current-visits')
            }
          }
        })

      } catch (error) {
        console.log(error)
      }
    },
    updateApp() {
      location.reload();
    }
  }
};
</script>

<style scoped>
.logo-img {
  max-width: 150px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.settings-header {
  background-color: #34657f;
  color: white;
  padding: 0px;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.settings-container {
  margin-top: -30px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}

.setting-item:last-child {
  border-bottom: none;
}

.setting-item .form-check {
  margin-right: 10px;
}

.setting-item button {
  min-width: 120px;
}

.setting-action {
  text-align: center;
  margin-top: 30px;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  padding: 12px;
  border-radius: 30px;
  width: 100%;
}
</style>
