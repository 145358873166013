<template>
  <!-- Header -->
  <div class="header-home">
    <div class="user-info" style="padding-top: 20px;padding-bottom: 40px;">Monitor:
      <strong>{{ (user.first_name ? user.first_name : '') + ' ' + (user.last_name ? user.last_name : '') }}</strong>
    </div> <!-- Replace with dynamic user data -->
  </div>

  <!-- Camps Container -->
  <div style="padding-right: 10px;padding-left: 10px;    margin-bottom: 100px;">
    <div class="container camps-container">
      <!-- Camp Item Example -->
      <template v-if="data.length">
        <template v-for="(item,index) in data">
          <div class="camp-item"
               v-if="(item.camp.camp_type != 3) || (item.camp.camp_type == 3 && item.employee.id == user.employee_id)">
            <div class="camp-info">
              <router-link :to="'/visits/'+item.id" @click.prevent
                           style="    text-decoration: none;color: #003a48;">
                <div class="mb-1">

                  <h5 class="badge bg-secondary"
                      style="margin: 0;    font-size: 0.9em;padding: 3px 5px;background: #0498ab !important;">
                    {{ item.visit_date }}</h5>
                  <h5 v-if="item.employee" class="badge bg-secondary mx-2"
                      style="margin: 0;    font-size: 0.9em;padding: 3px 5px;background: #ef851e !important;">
                    {{ item.employee.name }}
                  </h5>
                  <h5 v-else class="badge bg-secondary mx-2"
                      style="margin: 0;    font-size: 0.9em;padding: 3px 5px;background: #ef851e !important;">
                    Unknown
                  </h5>
                  <h5 v-if="item.hints_before_visit" class="badge bg-danger me-2"
                      style="margin: 0;    font-size: 0.9em;padding: 3px 5px;background: #ff4d4d !important;color: yellow !important;">
                    {{ item.hints_before_visit }}</h5>

                </div>
                <h5 style="margin: 0;    font-size: 1em;font-weight: bold">{{ item.camp.name_ar }}</h5>
              </router-link>

              <div class="d-flex" style="    flex-direction: column;">
                <div>
                  <div class="d-flex justify-content-between">
                    <ul style="margin-left: 0px;padding-left: 10px;margin-top: 5px" class="list-unstyled">
                      <li v-if="item.camp.rep_name" style="margin: 0;    font-size: 0.9em;margin-bottom: 3px">
                        <font-awesome-icon icon="user-group"
                                           style="padding: 0 0px;color: #2c3e50"/>
                        Beneficiaries:

                        {{ item.customers_count }}
                      </li>
                      <li v-if="item.camp.rep_name" style="margin: 0;    font-size: 0.9em;margin-bottom: 3px">
                        <font-awesome-icon icon="user"
                                           style="padding: 0 0px;color: #2c3e50"/>
                        {{ item.camp.rep_name.trim() }}
                      </li>
                      <li v-if="item.camp.rep_phone" style="margin: 0;    font-size: 0.9em;margin-bottom: 3px">
                        <a :href="'tel:'+(item.camp.rep_phone.trim())" style="text-decoration: none">
                          <font-awesome-icon icon="phone"
                                             style="padding: 0 0px;color: #2c3e50"/>
                          {{ item.camp.rep_phone.trim() }}</a>
                      </li>
                      <li v-if="item.camp.location" style="margin: 0;    font-size: 0.9em;">
                        <a :href="'geo:'+item.camp.location" @click="copyCoordinates(item.camp.location,item)"
                           :style="(item.location_copied ? 'background: #eaffd2;' : '')+'padding: 2px 0px;text-decoration: none'"
                           style="">
                          <font-awesome-icon icon="location"
                                             style="padding: 0 0px;color: #2c3e50"/>
                          Copy Location
                          <b style="font-style: italic;" v-if="item.location_copied">Copied</b>
                        </a>
                      </li>
                    </ul>

                    <div>
                      <button type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#sync-modal"
                              :style="'position: relative;padding:10px;    border-radius: 10px !important;'"
                              :class="['btn',(item.last_sync_at ? 'sync-btn' : 'sync-btn-white'),((!is_online || item.sync_loader) || (user.employee_id != item.employee_id && !$root.is_can_sync_activated) ) ? 'disabled' : '']"
                              :disabled="(!is_online || item.sync_loader) || (user.employee_id != item.employee_id && !$root.is_can_sync_activated)"
                              @click="show_rating_modal($event,item.id,item,index)">
                        Sync{{ item.last_sync_at ? 'd' : '' }}
                        <font-awesome-icon v-if="item.last_sync_at"
                                           style="color: white;    background: rgb(40, 167, 69);position: absolute;top: -15px;right: -12px;border-radius: 50%;padding: 5px;border: 3px solid white;"
                                           icon="check"/>
                        <font-awesome-icon
                            v-else-if="(user.employee_id == item.employee_id || $root.is_can_sync_activated)"
                            style="color: #34657f;    background: white;position: absolute;top: -15px;right: -12px;border-radius: 50%;padding: 5px;border: 1px solid #34657f;"
                            icon="clock"/>

                        <div v-if="item.sync_loader" class="spinner-border text-black"
                             style="    --bs-spinner-width: 1rem;--bs-spinner-height: 1rem;" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>

                      </button>

                    </div>
                  </div>
                </div>
                <div>
                  <p style="margin: 0;font-size: 8px" v-if="item.last_sync_at"> Last Sync: {{ item.last_sync_at }}</p>

                </div>
              </div>
            </div>


          </div>
        </template>
      </template>
      <template v-else>
        <h3 style="text-align: center">There is no Distributions.</h3>
        <h5 style="text-align: center">Download it from Settings.</h5>
      </template>

      <sync-modal></sync-modal>
      <!-- Add more camp items as needed -->
    </div>
  </div>


</template>
<script>
import {addOrUpdateData, getAllData, clearAllData, getDataByKey} from '@/database';
import SyncModal from './SyncModal'
import eventBus from "@/eventBus";


export default {
  components: {'sync-modal': SyncModal},
  data() {
    return {
      data: [],
      user: {},
      showInstallButton: false,
      loading: false,
      error: null,
      is_online: navigator.onLine,
    };
  },
  mounted() {
    // this.preventPullToRefresh();
    eventBus.on('refresh-list', function () {
      this.getScreenData();
    }.bind(this))
    this.getScreenData();
    // this.is_online = true;
    // this.is_online = navigator.onLine;
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

  },
  beforeDestroy() {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus() {
      this.is_online = navigator.onLine; // Update the status based on the navigator's online property
    },
    async getScreenData() {
      try {
        // if (navigator.onLine) {
        //   var response = await this.$axios.get('/api/visits/current');
        //   this.data = response.data.data
        //   await addOrUpdateData(response.data.data, 'current-visits')
        // } else {
        getAllData('current-visits').then((data) => {
          this.data = data;
          for (let i = 0; i < this.data.length; i++) {
            this.data[i].location_copied = false;
            this.data[i].sync_loader = false;
          }
        })
        getAllData('auth-user').then((data) => {
          this.user = data[0];

        })
        // }

        // getDataByKey(17182,'current-visits').then((data) => {
        //   console.log(data,'data')
        // })
      } catch (error) {
        console.log(error)
      }
    },
    async show_rating_modal(event, visit_id, item, index) {
      eventBus.emit('show_rating_modal', {
        item: item,
        visit_id: visit_id,
        index: index,
      })
    },

    preventPullToRefresh() {
      let startY = 0;

      document.addEventListener('touchstart', function (e) {
        startY = e.touches[0].clientY; // Record the initial touch position
      });

      document.addEventListener('touchmove', function (e) {
        const currentY = e.touches[0].clientY;

        // If scrolling down from the top of the page, prevent the default pull-to-refresh
        if (currentY > startY && window.scrollY === 0) {
          e.preventDefault();
        }
      }, {passive: false});
    },
    copyCoordinates(location, item) {
      navigator.clipboard.writeText(location)
          .then(() => {
            item.location_copied = true;
            setTimeout(function () {
              item.location_copied = false;
            }.bind(this), 1500)
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
    },
  }
};
</script>

<style scoped>

.header-home {
  background-color: #34657f;
  color: white;
  padding: 0px;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.user-info {
  margin: 10px 0;
  font-size: 1em;
  color: white;
}

.camps-container {
  margin-top: -30px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.camp-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #e0e0e0;
}

.camp-item:last-child {
  border-bottom: none;
}

.camp-info {
  flex-grow: 1;
  margin-right: 0px;
  text-align: left;
}

.sync-btn {
  background-color: #28a745;
  color: white;
  border-radius: 25px;
  padding: 10px 15px;
}

.sync-btn-white {
  background-color: #34657f;
  color: white;
  border-radius: 25px;
  padding: 10px 15px;
  border: 1px solid #34657f !important;
}

.sync-btn:hover {
  background-color: #218838;
}
</style>
